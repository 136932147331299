<template>
    <v-card tile max-height="400"  width="320">
        <v-toolbar
                dense 
                class="label f12 fw600 primary-font secondary-2--text"
                :elevation="offsetTop === 0 ? '0' : '3'"
                
            >
                <v-list-item class="d-flex flex-row align-center justify-space-between px-0">
                    <FormLabel :label="'Notifications'" class="mr-auto" />
                    <v-btn dense text small class="caption secondary-2--text px-1 ml-auto" @click.stop="$emit('markAll')"> Mark all as read </v-btn>
                </v-list-item>
                
        </v-toolbar>
        <v-divider></v-divider>

        <LinearProgress v-if="loading" />
        <v-list id="notification-scroll-list" width="320" dense height="240" dense style="overflow-y: auto;" v-scroll.self="onScroll">
            <v-container v-if="!loading && notification.length === 0" class="secondary-2--text f12 text-center">
                You have no notifications
            </v-container>
            <section v-else-if="notification.length > 0" >
                <v-list-item dense v-for="(item , i) in notification" :key="i" :class="!item.read_at && 'primary-1'" @click="$emit('readMessage', item)">
                    <v-list-item-avatar :color="item.type.split('\\')[2] === 'GroupMessageNotification' ? 'orange lighten-5' : ''">
                        <v-icon v-if="item.type.split('\\')[2] === 'GroupMessageNotification'" color="orange lighten-2">mdi-account-group-outline</v-icon>
                        <v-img 
                            v-else :src="item.data.photo ? item.data.photo : require('@/assets/default-photo.png')"
                            v-on:error="require('@/assets/default-photo.png')"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-1" v-if="item.type.split('\\')[2] === 'AnnouncementNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }} <b>{{ item.data.course }}</b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-1" v-if="item.type.split('\\')[2] === 'PrivateMessageNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }}
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-1" v-if="item.type.split('\\')[2] === 'AnnouncementCommentNotification'">
                        <span class="f12" v-if="role === 'user'">
                            <b>{{ item.data.name }}</b> replied to your comment in <b> {{ item.data.course }} </b>
                        </span>
                        <span class="f12" v-if="role === 'instructor'">
                            <b>{{ item.data.name }}</b> commented to your announcement on <b> {{ item.data.course }} </b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-1" v-if="item.type.split('\\')[2] === 'GroupMessageNotification'">
                        <span class="f12" v-if="item.data.synced_users">
                            <b>{{ item.data.name }}</b> added you to their group <b> {{ item.data.group_name }} </b>
                        </span>
                        <span class="f12" v-else>
                            <b>{{ item.data.name }}</b> sent a message in <b> {{ item.data.group_name }} </b>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="py-1" v-if="item.type.split('\\')[2] === 'ShareAssessmentNotification'">
                        <span class="f12">
                            <b>{{ item.data.name }}</b> {{ item.data.text }}
                            <section class="d-flex align-center">
                                <v-icon size="15" color="primary">mdi-file-outline</v-icon> <b> {{ item.data.assessment }}</b>
                            </section>
                        </span>
                        <v-list-item-subtitle class="f10">{{ $dateFormat.fromNow(item.created_at) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </section>
        </v-list>
    </v-card>
</template>

<script>
export default {
    props: ["notification", "role", "loading", "page", "total"],
    data: () => ({
        offsetTop: 0,
    }),
    mounted() {
        const masonry = document.querySelector('#notification-scroll-list');
        masonry.addEventListener('scroll', () => {
            if (masonry.scrollHeight - masonry.scrollTop <= masonry.clientHeight) {
                this.$emit('fetch')
            }
        })
    },
    methods: {
        onScroll (e) {
            this.offsetTop = e.target.scrollTop
        },
    }
}

</script>